import { defineAsyncComponent } from "vue";
import ErrorPage from "./pages/Error/index.vue";

import { Root } from "./pages";

import { RouteNames } from "./utils/routes";

export const routes = [
  {
    path: "/:token",
    component: Root,
    children: [
      {
        path: "onboarding",
        name: RouteNames.Onboarding,
        component: async () =>
          defineAsyncComponent({
            loader: () => import("./pages/Onboarding/index.vue"),
            errorComponent: ErrorPage,
          }),
      },
      {
        path: "home",
        name: RouteNames.Home,
        component: async () =>
          defineAsyncComponent({
            loader: () => import("./pages/Home/index.vue"),
            errorComponent: ErrorPage,
          }),
      },
      {
        path: "assets/all",
        name: RouteNames.Assets,
        component: async () =>
          defineAsyncComponent({
            loader: () => import("./pages/Assets/index.vue"),
            errorComponent: ErrorPage,
          }),
      },
      {
        path: "assets/:assetToken",
        name: RouteNames.Spend,
        component: async () =>
          defineAsyncComponent({
            loader: () => import("./pages/Spend/index.vue"),
            errorComponent: ErrorPage,
          }),
      },
    ],
  },
  {
    name: RouteNames.NewSpend,
    path: "/-/:assetToken",
    component: async () =>
      defineAsyncComponent({
        loader: () => import("./pages/Spend/index.vue"),
        errorComponent: ErrorPage,
      }),
  },
  {
    path: "/error",
    name: RouteNames.Error,
    component: ErrorPage,
  },
];
